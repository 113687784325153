import { updateGlobals } from './'
import { apiCall } from '../../utils'
import axiosInstance from '../../utils/axiosConfig'

export const defaultUpdates = (key, val) => ({
  type: 'UPDATE_DEFAULTS',
  data: { key, val }
})

export const updateDefaults = (data) => {
  let colors =  data?.colors
  
  let materials = data?.materials
  
  let parts = data?.parts
  let sizes = data?.sizes

  let materialprice = data?.materialPrice

  // let data =[
  //   colors : [...colors],
  //   {parts},
  //   {materials},
  //   {sizes}
  // ]
  return (dispatch) => {
    // apiCall({ action: 'cjd_defaults' })
      // .then(res => {
        // const data = res.data;
        // console.log("data" , data)
        // Object.keys(data).map( val => {
          // dispatch( defaultUpdates(val, data[val]) )
          dispatch(defaultUpdates("colors" , colors))
          dispatch(defaultUpdates("parts", parts))
          dispatch(defaultUpdates("sizes" , sizes))
          dispatch(defaultUpdates("materials" , materials))
          dispatch({
            type : 'UPDATE_PRICE',
            payload : {
              category : 'materials',
              value : materialprice
            }
          })
          // return true
        // } )
        dispatch( updateGlobals('loading', false) )
      // });
  }
}